<template>
  <div class="message__text">
    <div class="message__content">
      <span v-html="messageHTML"></span>
    </div>
    <div
      v-if="showOverflowButton"
      @click="showMore"
      class="message__button"
      role="button"
    >
      Read more
    </div>
  </div>
</template>

<script>
const regexes = [
  {
    pattern: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
    replacement: '<a class="message__url" href="$&" rel="noopener noreferrer" target="_blank">$&</a>',
    isUrl: true,
  },
  {
    pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    replacement: '<a class="message__url" href="mailto:$&" rel="noopener noreferrer" target="_blank">$&</a>',
  },
  {
    pattern: /^\*(.*?)\*$/,
    replacement: '<b>$1</b>',
  },
  {
    pattern: /^_(.*?)_$/,
    replacement: '<i>$1</i>',
  },
  {
    pattern: /^~(.*?)~$/,
    replacement: '<span class="message__strikethrough">$1</span>',
  },
  {
    pattern: /^`{1,3}(.*?)`{1,3}$/,
    replacement: '<span class="message__monospace">$1</span>',
  },
];

export default {
  props: {
    message: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      hideLargeText: true,
    };
  },
  computed: {
    messageHTML() {
      return this.addHTMLTags(this.messageText);
    },
    messageText() {
      if (this.showOverflowButton) {
        return `${this.capMessage(this.message.text)}...`;
      }

      return this.message.text;
    },
    textOverflows() {
      return this.message.text && this.message.text.length > 500
        && this.message.text.length - 500 > 50;
    },
    showOverflowButton() {
      return this.textOverflows && this.hideLargeText;
    },
  },
  methods: {
    capMessage(str) {
      return str.substring(0, 500);
    },
    showMore() {
      this.hideLargeText = false;
    },
    addHTMLTags(str) {
      const cleanStr = this.escapeHTML(str);

      return cleanStr.split(/\r\n|\n/).map(line => line.split(' ').map((word) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const regex of regexes) {
          const pattern = new RegExp(regex.pattern);

          if (pattern.test(word)) {
            if (regex.isUrl ?? false) {
              const prependProtocol = !word.startsWith('http');
              const replaced = prependProtocol ? `https://${word}` : word;

              return replaced.replace(regex.pattern, regex.replacement);
            }

            return word.replace(regex.pattern, regex.replacement);
          }
        }

        return word;
      }).join(' ')).join('\n');
    },
    escapeHTML(str) {
      return str.replace(/[&<>'"]/g,
        tag => ({
          '&': '&amp;',
          '<': '&lt;',
          '>': '&gt;',
          "'": '&#39;',
          '"': '&quot;',
        }[tag]));
    },
  },
};
</script>
